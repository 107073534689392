const HexPrinter = (): { init: () => void } => {
    const init = (): void => {
        const printElements: Element[] | null = [...Array.from(document.querySelectorAll('[data-print-bg-target]'))];
        if (0 === printElements.length) {
            return;
        }

        for (let element of printElements) {
            element.innerHTML = getHex(element);
        }
    }

    const getRgb = (element: Element): string => {
        const targetSelector = '.bg-' + element.getAttribute('data-print-bg-target')
        const target = document.querySelector(targetSelector)
        if (null === target) {
            return '';
        }
        return getComputedStyle(target).getPropertyValue('background-color')
    }

    function componentToHex(c: number): string {
        const hex = c.toString(16);
        return hex.length == 1 ? "0" + hex : hex;
    }

    function rgbToHex(r: number, g: number, b: number): string {
        return "#" + componentToHex(r) + componentToHex(g) + componentToHex(b);
    }

    function getHex(element: Element): string {
        const rgb = getRgb(element);
        let values = rgb.match(/rgba?\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/);
        if (null === values) {
            return ''
        }
        return rgbToHex(parseInt(values[1]),parseInt(values[2]),parseInt(values[3])).toUpperCase()
    }

    return {
        init: init
    }
}

const hexPrinter = HexPrinter();

document.addEventListener('DOMContentLoaded', () => {
    hexPrinter.init();
});
