import {cookieMachine} from "./CookieMachine";
import {availableTransitions} from "../../../src/scripts/components/AvailableTransitions";

const TraderSideBar = (): { init: () => void } => {
    const init = (): void => {
        const sideBar: Element | null = document.querySelector('#sidebar-trader')
        const sideBarOpen: Element | null = document.querySelector('#open-side-bar-trader')
        const sideBarClose: Element | null = document.querySelector('#close-side-bar-trader')
        if (null === sideBar || null === sideBarOpen || null === sideBarClose) {
            return;
        }

        if (cookieMachine.getCookie('trader-sidebar-closed') === 'false') {
            sideBar.classList.remove(availableTransitions.hide.status);
            sideBarOpen.classList.remove(availableTransitions.fadeIn.status);
        }

        sideBarOpen.addEventListener('click', () => {
            cookieMachine.setCookie('trader-sidebar-closed', 'false', 1440)
        });
        sideBarClose.addEventListener('click', () => {
            cookieMachine.setCookie('trader-sidebar-closed', 'true', 1440)
        });
    }

    return {
        init: init
    }
}

const traderSideBar = TraderSideBar();

document.addEventListener('DOMContentLoaded', () => {
    traderSideBar.init();
});