import 'prismjs'
import 'prismjs/plugins/normalize-whitespace/prism-normalize-whitespace'
import 'prismjs/plugins/toolbar/prism-toolbar'
import 'prismjs/plugins/copy-to-clipboard/prism-copy-to-clipboard'

declare var Prism: any;

const CodeExample = (): { init: () => void } => {
    const init = (): void => {
        const exampleElements: Element[] | null = [...Array.from(document.querySelectorAll('.code-example'))];
        if (0 === exampleElements.length) {
            return;
        }

        for (let example of exampleElements) {
            let exampleCode = getExampleCode(example)
            appendExampleCodeElement(example, exampleCode)
        }
    }

    const appendExampleCodeElement = (parent: Element, code: string): void => {
        code = normalizeCodeWhitespace(code);
        code = Prism.highlight(code, Prism.languages.html, 'haml')
        const preEl = document.createElement('pre');
        preEl.classList.add('code-example__source')
        const codeEl = document.createElement('code');
        codeEl.classList.add('language-html')
        codeEl.insertAdjacentHTML('beforeend', code)
        preEl.appendChild(codeEl)
        parent.appendChild(preEl)
    }

    const normalizeCodeWhitespace = (code: string): string => {
        const normalizer = Prism.plugins.NormalizeWhitespace;
        const options = {
            'remove-trailing': true,
            'remove-indent': true,
            'left-trim': true,
            'right-trim': true,
            'break-lines': 80,
            'remove-initial-line-feed': true,
            'tabs-to-spaces': 4,
        };
        return normalizer.normalize(code, options);
    }

    const getExampleCode = (parent: Element): string => {
        const codeAreaEl: HTMLElement | null = parent.querySelector('.code-example__content');

        if (null === codeAreaEl) {
            return 'undefined';
        }

        return codeAreaEl.innerHTML;
    }

    return {
        init: init
    }
}

const codeExample = CodeExample();

document.addEventListener('DOMContentLoaded', () => {
    codeExample.init();
});
