import Slugify from "./Slugify";
import { cookieMachine } from "./CookieMachine";

const PageIndexGenerator = (): { init: () => void } => {
    const topBarSelector: string = '#top-bar'
    const pageSelector: string = '.page'
    const parentSelector: string = '.page-content'
    const collapseClass: string = 'hide'
    const noAnimModifier: string = 'page-content--init'
    const headingsSelector: string =
        'h1:not(.code-example__content h1), ' +
        'h2:not(.code-example__content h2), ' +
        'h3:not(.code-example__content h3), ' +
        'h4:not(.code-example__content h4), ' +
        'h5:not(.code-example__content h5), ' +
        'h6:not(.code-example__content h6)'

    let indexElement: Element;
    let indexHtml: string = ''
    let topBarHeight: number = 0;
    let sticky: boolean = false;

    const init = (): void => {
        const targetElement: Element | null = document.querySelector('[data-generate-page-index]');
        if (null === targetElement) {
            return;
        }

        const parent: Element | null = targetElement.closest(parentSelector)
        const closeBtn: Element | null = document.querySelector('[data-close-page-index]');
        if (null === parent || null === closeBtn) {
            return;
        }

        indexElement = parent

        targetElement.innerHTML = getPageIndex();

        setTopBarHeight()

        document.addEventListener('scroll', () => {
            checkScrollForSticky(window.scrollY)
        });

        if (cookieMachine.getCookie('index-hidden') === 'true') {
            //todo: move to php
            togglePageIndex(false)
        }

        closeBtn.addEventListener('click', () => {
            togglePageIndex()
        });
    }

    const togglePageIndex = (animate: boolean = true): void => {
        const offset = parseInt(getComputedStyle(indexElement).getPropertyValue('width'), 10);
        //handle animation
        if (animate && indexElement.classList.contains(noAnimModifier)) {
            indexElement.classList.remove(noAnimModifier)
        }

        if (!animate && !indexElement.classList.contains(noAnimModifier)) {
            indexElement.classList.add(noAnimModifier)
        }

        //handle logic
        if (indexElement.classList.contains(collapseClass)) {
            indexElement.classList.remove(collapseClass)
            indexElement.removeAttribute('style')
            cookieMachine.setCookie('index-hidden', false, 1440);
            return
        }
        indexElement.classList.add(collapseClass)
        indexElement.setAttribute('style', 'right: ' + -offset + 'px;')
        cookieMachine.setCookie('index-hidden', true, 1440);
    }

    const getPageIndex = (): string => {
        if ('' !== indexHtml) {
            return indexHtml
        }

        const page = document.querySelector(pageSelector)
        if (null === page) {
            return 'No content'
        }

        indexHtml = generatePageIndex(page)
        return indexHtml
    }

    const assignHeadingsId = (headings: Element[]): string[] => {
        let id = 0;
        let ids = [];
        for (let heading of headings) {
            if (heading.hasAttribute('id')) {
                ids.push(heading.id)
                continue
            }

            const genId = Slugify(heading.innerHTML) + '-' + id
            ids.push(genId)
            heading.setAttribute('id', genId)
            id++;
        }

        return ids
    }

    const generatePageIndex = (element: Element): string => {
        const pageHeadings: Element[] | null = [...Array.from(element.querySelectorAll(headingsSelector))];
        if (0 === pageHeadings.length) {
            return 'No content'
        }

        const hIds = assignHeadingsId(pageHeadings)
        let i = 0;
        const tags = [];

        (function recurse(depth) {
            let unclosedLi = false;
            while (i < pageHeadings.length) {
                const heading = pageHeadings[i]
                const headingLevel = parseInt(heading.nodeName.slice(1)) - 1
                const link = '<a class="page-content__link" href="#' + hIds[i] + '">' + heading.innerHTML + '</a>'
                if (headingLevel < depth) {
                    break;
                } else if (headingLevel === depth) {
                    if (unclosedLi) tags.push('</li>');
                    unclosedLi = true;
                    tags.push('<li class="page-content__item">', link);
                    i++;
                } else {
                    tags.push('<ul>');
                    recurse(depth + 1);
                    tags.push('</ul>');
                }
            }
            if (unclosedLi) tags.push('</li>');
        })(-1);
        return tags.join('\n');
    }

    const setTopBarHeight = (): void => {
        const topBar: Element | null = document.querySelector(topBarSelector)
        if (null === topBar) {
            return;
        }

        topBarHeight = parseInt(getComputedStyle(topBar).getPropertyValue('height'), 10);
    }

    const checkScrollForSticky = (scrollY: number): void => {
        if (scrollY > topBarHeight) {
            if (!sticky) {
                indexElement.classList.add('sticky')
                sticky = true
            }
        }
        if (scrollY <= topBarHeight) {
            if (sticky) {
                indexElement.classList.remove('sticky')
                sticky = false
            }
        }
    }

    return {
        init: init,
    }
}

const pageIndexGenerator = PageIndexGenerator();

document.addEventListener('DOMContentLoaded', () => {
    pageIndexGenerator.init();
});