const CookieMachine = (): {
    setCookie: (
        name: string,
        value: any,
        expireMinutes: number
    ) => void;
    getCookie: (
        name: string
    ) => string;
} => {
    const setCookie = (name: string, value: any, expireMinutes: number): void => {
        const expire = new Date();
        let time = expire.getTime();
        time += expireMinutes * 60 * 1000;
        expire.setTime(time);
        document.cookie = name + '=' + value.toString()
            + '; expires=' + expire.toUTCString()
            + '; path=/';
    }

    const getCookie = (name: string): string => {
        const cookieName = name + "=";
        const decodedCookie = decodeURIComponent(document.cookie);
        const ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(cookieName) == 0) {
                return c.substring(cookieName.length, c.length);
            }
        }
        return '';
    }

    return {
        setCookie: setCookie,
        getCookie: getCookie
    }
}

const cookieMachine = CookieMachine();

export { cookieMachine };